<svelte:options tag="cdlc-mobile-post-text" />

<script>
  import debug from 'debug';
  import _ from 'lodash';
  import { onMount } from 'svelte';

  const log = debug('cdlc:MobilePostText');
  export let dispatch = () => {};
  export let max_length = 135;
  export let growable = false;
  export let styles = '';
  export let post = {};
  export let text = '';

  $: if (text === undefined) text = '';

  let isLongText = false;
  let links = [];
  let textContent = [];
  //Regex used to filter urls and emails.
  const fullUrlRegex =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
  const emailRegex =
    /(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/gi;
  const userAnchorRegex = /link:@(\S+)/gi;
  const httpsRegex = /https?:\/\//gi;
  const joinTextRegex = /[^ ]+/g;

  //Regex used to filter html tags and allow urls in textarea as hyperlinks
  const anchorRegex =
    /[\w()\/\\\;?\!+=:\# .-]*[^\n\t]*(((<[a\/][^\w][^<>]*>)[\w()\/\\\;?\!+=:\# .-]*[^<>]*<\/[a][^a-zA-Z<>]*>))[\w()\/\\\;?\!+=:\# .-]*[^<>\n\t]*/gi;
  const htmlTagRegex = /<[\A-Za-z][^<> ]*>(?!<\/a>)/gi;

  $: taggedUsers = _.get(post, 'taggedUsers') || [];
  $: hasScriptTag = _.includes(showableText, '<script ');
  $: extraClasses = !growable && text.length < 40 ? 'MobilePostText-large' : '';
  $: showableText = sliceText(text, max_length);
  $: isLongText =
    String(text || '').trim() !== String(showableText || '').trim() &&
    text.length > max_length;

  let body = undefined;

  onMount(async () => {
    await getLinks();
    if (links.length > 0) {
      await createAnchor().then(anchored => {
        text = anchored;
      });
    }
    body.querySelectorAll('.cdlc-user-anchor').forEach(anchor => {
      console.log('adding click', anchor.id);
      anchor.addEventListener('click', e =>
        dispatch('tagged-user-clicked', { id: anchor.id })
      );
    });
  });

  function getLinks() {
    return new Promise((resolve, reject) => {
      log('taggedUsers | text', text);
      // let removeNewLines = _.replace(text, '\n', '');
      let postWords = _.words(text, /[^ ]+/gi);
      const usernames = _.map(taggedUsers, u => {
        const formatted = `@${_.replace(u.username, ' ', '_')}`;
        log('taggedUsers | username formatted', formatted);
        return formatted;
      });

      _.each(postWords, (word, i) => {
        const trimmed = word.replace(/[^\w_@]/g, '');
        log('taggedUsers | word', trimmed, word, usernames);
        if (word.match(fullUrlRegex) || word.match(emailRegex)) {
          links.push(word);
        } else if (_.includes(usernames, trimmed)) {
          const newword = `link:${trimmed}`;
          postWords[i] = newword;
          links.push(newword);
        }
      });
      text = _.join(postWords, ' ');
      resolve(links);
    });
  }

  function sliceText(text, max_length) {
    text = String(text || '').trim();
    // NOTE: removed growable functionality
    if (text.length > parseFloat(max_length)) {
      isLongText = true;
      text = text.substr(0, parseFloat(max_length)).trim().concat('...');
    }
    return String(text || '').trim();
  }

  function isAnchor(link, textContent, type) {
    if (type === 'userAnchor') {
      const username = _.split(link, 'link:@')[1];
      const urlAnchor = document.createElement('div');
      const text = document.createTextNode(_.replace(link, 'link:', ''));
      const taggedUsers = _.map(post.taggedUsers, u => {
        u.tag_username = _.replace(u.username, ' ', '_');
        return u;
      });

      const user = _.find(taggedUsers, u => u.tag_username === username);

      urlAnchor.classList.add('cdlc-user-anchor');
      urlAnchor.setAttribute('id', user._id);
      urlAnchor.appendChild(text);

      let tempStr = textContent.join(' ');
      let finalContent = _.replace(tempStr, link, urlAnchor.outerHTML);
      return _.words(finalContent, joinTextRegex);
    } else {
      let address = link.match(fullUrlRegex)
        ? link.match(fullUrlRegex)
        : link.match(emailRegex);
      if (address && !link.match(httpsRegex) && type === 'url') {
        address = 'https://' + address;
      }
      if (address) {
        let urlAnchor = document.createElement('a');
        let urlLink = document.createTextNode(address);
        urlAnchor.appendChild(urlLink);
        if (type === 'url') {
          urlAnchor.setAttribute('target', '_blank');
          urlAnchor.title = address;
          urlAnchor.href = address;
          if (
            urlAnchor.outerHTML.match(anchorRegex) &&
            !urlAnchor.outerHTML.match(htmlTagRegex) &&
            !urlAnchor.outerHTML.match(hasScriptTag)
          ) {
            let tempStr = textContent.join(' ');
            let finalContent = _.replace(tempStr, link, urlAnchor.outerHTML);
            return _.words(finalContent, joinTextRegex);
          }
        } else if (type === 'email') {
          urlAnchor.setAttribute('target', '_system');
          urlAnchor.href = 'mailto:' + address;
          if (
            urlAnchor.outerHTML.match(anchorRegex) &&
            !urlAnchor.outerHTML.match(htmlTagRegex) &&
            !urlAnchor.outerHTML.match(hasScriptTag)
          ) {
            let tempMail = textContent.join(' ');
            let finalmail = _.replace(tempMail, address, urlAnchor.outerHTML);
            return _.words(finalmail, joinTextRegex);
          }
        }
      }
    }
    return false;
  }

  function createAnchor() {
    return new Promise(resolve => {
      let emailAddresses = _.words(text, emailRegex);
      let linkAddresses = _.words(text, fullUrlRegex);
      let userAnchors = _.words(text, userAnchorRegex);
      textContent = _.words(text, joinTextRegex);
      emailAddresses.forEach(email => {
        textContent = isAnchor(email, textContent, 'email');
      });
      linkAddresses.forEach(link => {
        textContent = isAnchor(link, textContent, 'url');
      });
      userAnchors.forEach(userAnchor => {
        textContent = isAnchor(userAnchor, textContent, 'userAnchor');
      });
      text = textContent.join(' ');
      resolve(text);
    });
  }
</script>

{#if text}
  <div class="MobilePostText">
    <p class="MobilePost-caption {extraClasses}" style={styles}>
      {#await getLinks() then links}
        <div bind:this={body} style="white-space:pre-wrap">
          {#if links.length > 0}
            {#await createAnchor() then text}
              {@html text}
            {/await}
          {:else}
            {@html showableText}
          {/if}
        </div>
      {/await}
      {#if isLongText}
        <a
          style="cursor: pointer"
          on:click={e => {
            try {
              e.preventDefault();
              e.stopPropagation();
            } catch (e) {}

            max_length = 9999999999;
          }}>See More</a
        >
      {/if}
    </p>
  </div>
{/if}

<style src="./MobilePostText.scss">
</style>
