<svelte:options tag="cdlc-ad" />

<script context="module">
  import debug from 'debug';
  import _ from 'lodash';
  import { createEventDispatcher, onMount } from 'svelte';
  import { get_current_component } from 'svelte/internal';

  const log = debug('cdlc:Ad');
  const API =
    localStorage.getItem('JOBBOARD_API') || 'https://dedicatedjobs.cdllife.com';

  let placementRequests = [],
    testFlight = getQueryParam('adnflid'),
    testFlightReal = getQueryParam('adnflidreal'),
    adnPreviewId = getQueryParam('adnPreview'); // adn from our db to create faux preview

  function getQueryParam(s) {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(s);
    } catch (e) {
      return null;
    }
  }

  async function requestPlacement(req) {
    if (testFlight) {
      req.flightId = parseInt(testFlight);
      req.skipSelection = true;
      req.enableBotFiltering = false;
      req.skipFilters = {
        geodistance: true,
        geolocation: true,
        placementLimit: true,
        siteZone: true,
        keyword: true,
        facet: true,
      };
    }

    const d = {
      req: req,
    };

    placementRequests.push(d);

    let p = new Promise((resolve, reject) => {
      d.resolve = resolve;
      d.reject = reject;
    });

    setTimeout(() => {
      checkRequests();
    }, 150);

    return p;
  }

  function tryLogEvent(event) {
    try {
      window['cpix']('event', event);
    } catch (e) {
      console.log('cpix fail', event);
    }

    try {
      window['gtag']('event', event, {
        title: document.title,
      });
    } catch (e) {
      console.log('gtag fail', event);
    }
  }

  function getUser() {
    const user = {
      key:
        localStorage.getItem('CDLC|AZ|USER') ||
        'ur-' + String(Math.random()) + '-' + String(new Date().getTime()),
    };

    if (testFlight) {
      return {
        key: String(Math.random()) + '-' + String(new Date().getTime()),
      };
    }

    return user;
  }

  async function checkRequests() {
    if (!placementRequests.length) {
      return;
    }
    let reqs = placementRequests,
      decisionAPI = `https://e-10445.adzerk.net/api/v2`;

    placementRequests = [];

    const body = {
      user: getUser(),
      placements: _.shuffle(reqs.map(d => d.req)),
    };

    reqs.forEach(r => {
      r.req.divName = _.uniqueId('azdecision');
      if (r.req.keywords) {
        body.keywords = r.req.keywords;
      }
    });

    if (!body.keywords) {
      try {
        const parsed = JSON.parse(localStorage.getItem('AID')),
          z = parsed.z;
        // NOTE: on 2/8/2022 due to this discussion below, we decided
        // to only show job ads if we know who you are:
        // https://www.notion.so/cub-ventures/Only-show-job-ads-to-drivers-outside-recommended-widget-78984429b26545feb1beb6612bba50ac
        // || parsed.zf;
        if (z) {
          body.keywords = ['zip1-' + String(z)[0]];
        }
      } catch (e) {}
    }

    log('request body', body);

    let firstFound;

    try {
      return fetch(decisionAPI, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(body),
      })
        .then(res => res.json())
        .then(data => {
          log('loaded ad', data);

          const user = _.get(data, 'user.key');
          log('setting user', user);

          localStorage.setItem('CDLC|AZ|USER', user);

          _.each(reqs, r => {
            const decisionId = r.req.divName;
            let ad = _.get(data, 'decisions.' + decisionId) || firstFound;

            if (ad) {
              if (ad instanceof Array) {
                ad = _.shuffle(ad)[0];
                if (testFlight) {
                  firstFound = ad;
                }
              }
              ad.creative = _.first(_.get(ad, 'contents'));
              ad.adType = _.get(
                ad,
                'creative.data.customData.adType',
                'default'
              );

              r.resolve(ad);
            } else {
              r.reject('not filled');
            }
          });
        });
    } catch (e) {
      console.error('error in checkRequests', e);
    }
  }

  async function getAdnPreview() {
    try {
      let previewAd = await fetch(
        `${API}/api/adn/forcedPreview/${adnPreviewId}`
      )
        .then(res => res.json())
        .then(res => res && res.data);

      if (previewAd && previewAd.length) {
        return previewAd[0];
      }
    } catch (error) {
      console.error(error);
    }
  }
</script>

<script>
  import './_components/ReputationAd/ReputationAd.svelte';

  const svelteDispatch = createEventDispatcher(),
    component = get_current_component(),
    dispatch = (name, detail) => {
      svelteDispatch(name, detail);
      component.dispatchEvent &&
        component.dispatchEvent(new CustomEvent(name, { detail }));
    },
    adSizes = {
      default: ['MEDIUM_SQUARE', 'MEDIUM_RECTANGLE'],
      '1120901': {
        RECOMMENDED_WIDGET: [
          'MEDIUM_SQUARE',
          'MEDIUM_RECTANGLE',
          'TWICE_TALL',
          'TALL_SCROLL',
        ],
      },
      '1180463': {
        RECOMMENDED_WIDGET: [
          'MEDIUM_SQUARE',
          'MEDIUM_RECTANGLE',
          'TWICE_TALL',
          'TALL_SCROLL',
        ],
      },
      '1120900': {
        BELOW_COMMENTS_RECOMMENDED_WIDGET: [
          'MEDIUM_SQUARE',
          'MEDIUM_RECTANGLE',
          'TWICE_TALL',
          'TALL_SCROLL',
        ],
      },
    },
    adTypes = {
      MEDIUM_SQUARE: 174,
      MEDIUM_RECTANGLE: 5,
      TWICE_TALL: 4150,
      TALL_SCROLL: 5522,
    },
    zones = {
      // 1120901 is cdllife.com
      // to do -> add zone for reputation
      '1120901': {
        UNDER_ARTICLE: [219086, 219164],
        UNDER_ARTICLE_AMP: [223008, 219164],
        MOBILE_FOOTER: [219085, 219164],
        MOBILE_MID_ARTICLE: [219084, 219164],
        DESKTOP_SIDEBAR1: [219082, 219164],
        DESKTOP_SIDEBAR2: [219083, 219164],
        DEFAULT_POST: [219164],
        TOP_OF_PAGE: [219081],
        RECOMMENDED_WIDGET: [245731],
      },

      // 1120900 is mobile app
      // to do -> add zone for reputation
      '1120900': {
        NOTIFICATIONS: [246454],
        MESSAGES: [249946],
        ABOVE_VERTICALLY_STACKED_LATEST_NEWS: [249952],
        ABOVE_VERTICALLY_STACKED_TRENDING_NEWS: [249953],
        LATEST_NEWS_POSTS_BANNER: [249954],
        TRENDING_NEWS_POSTS_BANNER: [249955],
        BELOW_COMMENTS_RECOMMENDED_WIDGET: [246456],
      },

      // 1162190 is veterans in trucking
      '1162190': {
        DESKTOP_SIDEBAR1: [243066],
        DESKTOP_SIDEBAR2: [243067],
        MID_ARTICLE: [287225],
        MOBILE_FOOTER: [243069],
        MOBILE_MID_ARTCILE: [243068],
        TOP_OF_PAGE: [243065],
        UNDER_ARTICLE: [243070],
        UNDER_ARTICLE_AMP: [243072],
      },

      // dev cdllife.com
      // to do -> add zone for reputation
      '1180463': {
        MOBILE_FOOTER: [256122],
      },
    };

  export let site = '1120901';
  export let fill_rate = 1.0;
  export let zone = '';
  export let network = '10445'; //'10445' 10685
  export let size; // optional size
  export let keywords; // optional array of keywords
  export let show_ad_icon = false;
  export let show_close = false;
  export let forced_flight = '';
  export let ad_orientation = '';

  let hasBeenSeen = false,
    ready = false,
    inViewport = false,
    adOrientation = 'Ad--portrait',
    innerWidth,
    loadedAd,
    elContainer,
    adWrapper,
    adElement,
    imageOnly,
    rawAd = false;

  onMount(async () => {
    setTimeout(() => {
      if (forced_flight) {
        testFlight = forced_flight;
        testFlightReal = true;
      }
      if (ad_orientation) {
        adOrientation = ad_orientation;
      }
      ready = true;
    });
  });

  $: primaryColor = formatColor(
    _.get(loadedAd, 'creative.data.customData.primaryColor')
  );
  $: secondaryColor = formatColor(
    _.get(loadedAd, 'creative.data.customData.secondaryColor')
  );
  $: doubleVerifyTag = _.get(
    loadedAd,
    'creative.data.customData.doubleVerifyTag'
  );
  $: hasColor = primaryColor && secondaryColor;

  $: customCssStyle = hasColor
    ? `
  .Ad {
    --color-primary: ${primaryColor};
    --color-secondary: ${secondaryColor};
  }`
    : '';

  $: {
    if (!_.get(loadedAd, 'creative.data.customData.title', '')) {
      imageOnly = true;
    } else {
      imageOnly = false;
    }
  }

  // check if in viewport
  $: {
    // need log to innerWidth rerun computation
    log('innerWidth', innerWidth, elContainer);
    if (!inViewport && ready) {
      try {
        const rect = elContainer.getBoundingClientRect();

        log('zone', zone);
        if ((rect.x >= 0 || rect.y >= 0) && (rect.width || rect.height)) {
          inViewport = true;
        }
        log('rect', {
          rect,
          inViewport,
        });
      } catch (e) {}
    }

    if (rawAd) {
      resizeRawAdIframe();
    }
  }

  //check if visible
  $: {
    // need log to innerWidth rerun computation
    log('innerWidth', innerWidth, adWrapper);

    const width = _.get(adWrapper, 'clientWidth');

    log('width ', width);
    log('el', adWrapper);

    if (ad_orientation) {
      adOrientation = ad_orientation;
    } else {
      adOrientation = width > 500 ? 'Ad--landscape' : 'Ad--portrait';
    }
    log('orientation', adOrientation);
  }

  function setIntersectionObserver() {
    const observer = new IntersectionObserver(
      entries => {
        try {
          if (!entries[0].isIntersecting) return;

          if (hasBeenSeen || !loadedAd || (testFlight && !testFlightReal)) {
            return;
          }

          log('visible');
          hasBeenSeen = true;

          let it = document.createElement('img');
          it.src =
            'https://cdllife-mobile-notifications.herokuapp.com/api/track/i.gif?' +
            loadedAd.impressionUrl;
          it.border = 0;

          try {
            if (doubleVerifyTag) {
              const divFragment = document
                .createRange()
                .createContextualFragment(
                  `<div style="height: 0; width: 0; overfow:hidden">${doubleVerifyTag}</div>`
                );
              document.body.prepend(divFragment);
            }
          } catch (e) {
            console.error('error', e);
          }

          tryLogEvent('ad_impression');
        } catch (e) {
          console.error('error', e);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: [0.5],
      }
    );
    // The DOM element here should be a div that captures the whole content of the iframe.
    observer.observe(elContainer);
  }

  async function loadAd() {
    if (Math.random() > parseFloat(fill_rate)) {
      return Promise.reject();
    }

    try {
      let reqAdTypes = _.map(_.get(adSizes, site + '.' + zone), d => {
        return adTypes[d];
      });

      if (size && adTypes[size]) {
        reqAdTypes = [adTypes[size]];
      }

      if (!_.size(reqAdTypes)) {
        reqAdTypes = _.map(adSizes.default, d => {
          return adTypes[d];
        });
      }

      // testing adn from our db directly
      if (adnPreviewId) {
        return (loadedAd = await getAdnPreview());
      }

      return requestPlacement({
        networkId: network,
        siteId: site,
        zoneIds: _.get(zones, site + '.' + zone)
          ? _.get(zones, site + '.' + zone)
          : undefined,
        adTypes: reqAdTypes,
        enableBotFiltering: !localStorage.getItem('debug'),
        keywords,
      })
        .then(ad => {
          if (!adnPreviewId) {
            setTimeout(() => {
              setIntersectionObserver();
            }, 50);

            log('placement');
            log('  - site', site);
            log('  - zone', zone);
            log('  - ad', ad);
            handleRawCreatives(ad, ad && ad.creative);
          }

          dispatch('ad-filled');

          return (loadedAd = ad);
        })
        .catch(e => {
          log('error getting ad', e);
          setTimeout(() => {
            dispatch('fallback');
          });
          return Promise.reject(e);
        });
    } catch (e) {
      console.error('error', e);
    }
  }

  function handleRawCreatives(ad, creative) {
    if (creative && creative.type === 'raw') {
      rawAd = true;
      const responsive = _.get(creative, 'data.customData.responsive');

      setTimeout(() => {
        try {
          const content = creative.body,
            height = creative.data.height,
            width = creative.data.width;

          var adzerkDiv,
            doc,
            iframe_lang,
            iframe_title,
            ifrm,
            inner_html,
            lang,
            ref;

          ref = {};
          iframe_title = ref.iframe_title;
          iframe_lang = ref.iframe_lang;

          adzerkDiv = adWrapper;
          ifrm = document.createElement('iframe');
          ifrm.frameBorder = 0;
          ifrm.scrolling = 'no';
          ifrm.noresize = 'noresize';
          ifrm.marginheight = 0;
          ifrm.marginwidth = 0;
          if (responsive) {
            if (height !== 0) {
              ifrm.height = (height / width) * adWrapper.clientWidth;
            }
            if (width !== 0) {
              ifrm.width = '100%';
            }
          } else {
            if (height !== 0) {
              ifrm.height = height;
            }
            if (width !== 0) {
              ifrm.width = width;
            }
          }
          if (iframe_title) {
            ifrm.title = iframe_title;
          }
          adzerkDiv.appendChild(ifrm);

          if (ifrm.attachEvent) {
            ifrm.attachEvent('onload', function () {});
          } else {
            ifrm.onload = function () {};
          }
          lang = iframe_lang ? ' lang="' + iframe_lang + '"' : '';
          inner_html =
            `
          <!DOCTYPE HTML PUBLIC \"-//W3C//DTD HTML 4.01//EN\" \"http://www.w3.org/TR/html4/strict.dtd\">\n<html><head><style>div,iframe { top: 0; position:absolute; }</style></head><body style=\"margin:0px;padding:0px;\"><s${'cript'} type=\"text/javascript\">var inFIF=true;var inDapIF = true;</s${'cript'}>${content}</body></html>
          <script>
            try {
            window['_adDocWidth'] = ${window.innerWidth};
            } catch(e) {
            }
          </`.trim() + 'script>';

          if (/msie/.test(navigator.userAgent.toLowerCase()) || window.opera) {
            ifrm.contentWindow.contents = inner_html;
            resizeRawAdIframe(responsive);
            return (ifrm.src = 'javascript:window["contents"]');
          } else {
            doc = ifrm.contentDocument;
            doc.open();
            doc.write(inner_html);
            doc.close();
            resizeRawAdIframe(responsive);
            return ifrm;
          }
        } catch (e) {
          console.warn('error', { e, creative });
        }
      });
    }
  }

  function resizeRawAdIframe(responsive) {
    try {
      const iframe = adWrapper.querySelector('iframe'),
        creative = loadedAd.creative,
        height = creative.data.height,
        width = creative.data.width,
        containerWidth = adWrapper.clientWidth,
        scaledHeight = (height / width) * containerWidth;

      if (responsive) {
        iframe.height = scaledHeight;
        adWrapper.style.headers = scaledHeight;
      } else {
        if (scaledHeight < height) {
          iframe.style.transform = `scale(${_.round(
            scaledHeight / height,
            2
          )})`;
          iframe.style.transformOrigin = `top left`;
          adWrapper.style.height = scaledHeight + 'px';
        }
      }

      log('resized frame', {
        scaledHeight,
        height,
      });
      setIntersectionObserver();
    } catch (e) {}
  }

  function formatColor(c) {
    c = String(c || '');
    if (c && c[0] !== '#') {
      c = '#' + c;
    }
    return c;
  }

  function adClick() {
    log('clicked ad');
    window.open(loadedAd.clickUrl);

    tryLogEvent('ad_click');
  }

  function closeClick(event) {
    try {
      event.preventDefault();
      event.stopPropagation();
    } catch (e) {}

    dispatch('close');
  }
</script>

<div
  bind:this={elContainer}
  data-nosnippet="true"
  style="min-width: 1px; min-height: 1px"
>
  {#if show_ad_icon === true}
    <svg
      style="height: 20px; width: 30px; color:B3B3B3;"
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="ad"
      class="svg-inline--fa fa-ad fa-w-1"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      ><path
        fill="currentColor"
        d="M101.42 352h16.94c6.81 0 12.88-4.32 15.12-10.75l7.38-21.25h70.29l7.38 21.25A16 16 0 0 0 233.65 352h16.94c11.01 0 18.73-10.85 15.12-21.25L212 176.13A24.004 24.004 0 0 0 189.33 160h-26.66c-10.22 0-19.32 6.47-22.67 16.12L86.31 330.75C82.7 341.15 90.42 352 101.42 352zM176 218.78L194.48 272h-36.96L176 218.78zM352 352c9.93 0 19.4-2.02 28.02-5.68 2.94 3.41 7.13 5.68 11.98 5.68h16c8.84 0 16-7.16 16-16V176c0-8.84-7.16-16-16-16h-16c-8.84 0-16 7.16-16 16v36.42c-7.54-2.69-15.54-4.42-24-4.42-39.7 0-72 32.3-72 72s32.3 72 72 72zm0-96c13.23 0 24 10.77 24 24s-10.77 24-24 24-24-10.77-24-24 10.77-24 24-24zM464 64H48C21.5 64 0 85.5 0 112v288c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zm0 336H48V112h416v288z"
      /></svg
    >
  {/if}
  {#if ready && inViewport}
    {#await loadAd()}
      <!-- loading -->
    {:then ad}
      {#if ad}
        {#if _.get(ad, 'adType') === 'reputation'}
          <div bind:this={adWrapper} class="Reputation">
            <cdlc-ad-reputation-ad {site} {ad} />
          </div>
        {:else if ad.creative && ad.creative.type === 'raw'}
          <div bind:this={adWrapper} class="Ad is-raw" />
        {:else}
          <div bind:this={adWrapper}>
            {#if customCssStyle}
              <!-- split up cause weird formatting issue : /-->
              {@html '<sty' + 'le>' + customCssStyle + '</style>'}
            {/if}
            <div
              class={'Ad ' +
                (imageOnly ? 'Ad--imageOnly' : 'Ad--default') +
                ' ' +
                adOrientation +
                ' Ad--' +
                zone}
              class:Ad--withColors={hasColor}
              on:click={adClick}
              bind:this={adElement}
            >
              {#if show_close}
                <span class="Ad-close" on:click={closeClick}>
                  <svg
                    width="14"
                    height="13"
                    viewBox="0 0 14 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.28906 6.5L12.3125 2.51562L13.1328 1.69531C13.25 1.57812 13.25 1.38281 13.1328 1.22656L12.2734 0.367188C12.1172 0.25 11.9219 0.25 11.8047 0.367188L7 5.21094L2.15625 0.367188C2.03906 0.25 1.84375 0.25 1.6875 0.367188L0.828125 1.22656C0.710938 1.38281 0.710938 1.57812 0.828125 1.69531L5.67188 6.5L0.828125 11.3438C0.710938 11.4609 0.710938 11.6562 0.828125 11.8125L1.6875 12.6719C1.84375 12.7891 2.03906 12.7891 2.15625 12.6719L7 7.82812L10.9844 11.8516L11.8047 12.6719C11.9219 12.7891 12.1172 12.7891 12.2734 12.6719L13.1328 11.8125C13.25 11.6562 13.25 11.4609 13.1328 11.3438L8.28906 6.5Z"
                      fill="#989AA2"
                    />
                  </svg>
                </span>
              {/if}
              {#if show_ad_icon}
                <div class="Ad-icon">
                  <svg
                    style="height: 20px; width: 30px; color:B3B3B3;"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="far"
                    data-icon="ad"
                    class="svg-inline--fa fa-ad fa-w-1"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    ><path
                      fill="currentColor"
                      d="M101.42 352h16.94c6.81 0 12.88-4.32 15.12-10.75l7.38-21.25h70.29l7.38 21.25A16 16 0 0 0 233.65 352h16.94c11.01 0 18.73-10.85 15.12-21.25L212 176.13A24.004 24.004 0 0 0 189.33 160h-26.66c-10.22 0-19.32 6.47-22.67 16.12L86.31 330.75C82.7 341.15 90.42 352 101.42 352zM176 218.78L194.48 272h-36.96L176 218.78zM352 352c9.93 0 19.4-2.02 28.02-5.68 2.94 3.41 7.13 5.68 11.98 5.68h16c8.84 0 16-7.16 16-16V176c0-8.84-7.16-16-16-16h-16c-8.84 0-16 7.16-16 16v36.42c-7.54-2.69-15.54-4.42-24-4.42-39.7 0-72 32.3-72 72s32.3 72 72 72zm0-96c13.23 0 24 10.77 24 24s-10.77 24-24 24-24-10.77-24-24 10.77-24 24-24zM464 64H48C21.5 64 0 85.5 0 112v288c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zm0 336H48V112h416v288z"
                    /></svg
                  >
                </div>
              {/if}
              <div class="Ad-imageSection">
                <div
                  class="Ad-imageWrapper"
                  style={`padding-bottom: ${
                    (_.get(ad, 'creative.data.height') /
                      _.get(ad, 'creative.data.width')) *
                    100
                  }%`}
                >
                  <img
                    alt={_.get(ad, 'creative.data.customData.title', '')}
                    src={_.get(ad, 'creative.data.imageUrl')}
                  />
                </div>
              </div>
              {#if !imageOnly}
                <div class="Ad-adBody">
                  <div class="Ad-adTitle">
                    {_.get(ad, 'creative.data.customData.title', '')}
                  </div>
                  <div class="Ad-adText">
                    {_.get(ad, 'creative.data.customData.text', '')}
                  </div>
                  <button class="Ad-adButton">
                    {_.get(
                      ad,
                      'creative.data.customData.ctaText',
                      'Learn More'
                    )}
                  </button>
                </div>
                <slot name="after-button" />
              {/if}
            </div>
          </div>
        {/if}
      {/if}
    {:catch error}
      <slot name="fallback" />
    {/await}
  {/if}
</div>

<style src="./Ad.scss"></style>
